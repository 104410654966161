import classNames from "classnames"

import { FadeInSlideUp } from "@components/shared"
import { useSectionPadding } from "@hooks/sectionPadding"
import { useResponsiveLG } from "@hooks/shared"
import { ACFTwoColumnTextExtraBlock } from "@models/blocks"

export const TwoColumnTextExtraBlock = ({
  padding_top,
  padding_bottom,
  section_id,
  section_class,
  left_title,
  left_content,
  right_title,
  right_content,
}: ACFTwoColumnTextExtraBlock) => {
  const isResponsiveLG = useResponsiveLG()
  // Commented to "follow the design"
  // const sectionPadding = useSectionPadding(padding_top, padding_bottom)

  return (
    <section id={section_id} className={classNames("pt-14 lg:pt-24", section_class)}>
      <FadeInSlideUp className="wrapper-sm" threshold={!isResponsiveLG ? 0.25 : 0.5}>
        {(left_content || right_content) && (
          <div
            className={classNames("flex flex-col lg:flex-row lg:items-end lg:space-x-12", {
              "mb-7": left_title || right_title,
            })}
          >
            <div className="flex-1">
              {left_title && (
                <h2
                  className="app-h2 mb-8 lg:mb-0"
                  dangerouslySetInnerHTML={{ __html: left_title }}
                />
              )}
              {left_content && (
                <div
                  className="wysiwyg extra flex-1 lg:mt-12 lg:hidden"
                  dangerouslySetInnerHTML={{ __html: left_content }}
                />
              )}
            </div>

            <div className="mt-14 flex-1 lg:mt-0">
              {right_title && (
                <h2
                  className="app-h2 mb-8 lg:mb-0"
                  dangerouslySetInnerHTML={{ __html: right_title }}
                />
              )}
              {right_content && (
                <div
                  className="wysiwyg flex-1 lg:mt-12 lg:hidden"
                  dangerouslySetInnerHTML={{ __html: right_content }}
                />
              )}
            </div>
          </div>
        )}
        <div className="hidden space-x-12 lg:flex">
          <div
            className="wysiwyg content-text flex-1"
            dangerouslySetInnerHTML={{ __html: left_content }}
          />
          <div
            className="wysiwyg content-text flex-1"
            dangerouslySetInnerHTML={{ __html: right_content }}
          />
        </div>
      </FadeInSlideUp>
    </section>
  )
}
