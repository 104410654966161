import { createContext, useContext } from "react"

import { WPMedarbetarePosition } from "@models/medarbetare"

export interface MedarbetareContextShape {
  positions: WPMedarbetarePosition[]
  verksamhetsomraden: { databaseId: number; title: string }[]
  branscher: { databaseId: number; title: string }[]
}

export const MedarbetareContext = createContext<MedarbetareContextShape>({
  positions: [],
  verksamhetsomraden: [],
  branscher: [],
})
export const useMedarbetareContext = () => useContext(MedarbetareContext)
