import { motion, useInView } from "framer-motion"
import Link from "next/link"
import { useMemo, useRef } from "react"

import { PostCard } from "@components/shared"
import { useSectionPadding } from "@hooks/sectionPadding"
import { RightArrow } from "@icons/right-arrow"
import { RightArrowSm } from "@icons/right-arrow-sm"
import { ACFArticlesBlock } from "@models/blocks"
import { variantsPostGrid } from "@utils/animation"

export const ArticlesBlock = ({
  padding_top,
  padding_bottom,
  title,
  title_link,
  featured_posts,
}: ACFArticlesBlock) => {
  const ref = useRef<HTMLDivElement>(null!)
  const isInView = useInView(ref, { once: true, amount: 0.25 })
  const sectionPadding = useSectionPadding(padding_top, padding_bottom)

  const _title = title_link ? (
    <Link href={title_link} className="app-h2">
      {title}
    </Link>
  ) : (
    <h2 className="app-h2">{title}</h2>
  )

  const _cards = useMemo(
    () =>
      featured_posts.map((item, i) => (
        <motion.div
          key={item.slug}
          variants={variantsPostGrid}
          initial="initial"
          animate="animate"
          custom={isInView}
          transition={{ ease: "easeInOut", duration: 1.25, delay: i * 0.3 }}
        >
          <PostCard
            id={item.id}
            title={item.title}
            slug={item.slug}
            date={item.date}
            categories={item.categories.edges.map(({ node: { slug } }) => slug) ?? null}
            postType="posts"
          />
        </motion.div>
      )),
    [featured_posts, isInView]
  )

  return _cards.length > 0 ? (
    <section ref={ref} style={sectionPadding}>
      <div className="wrapper-sm xl:px-0">
        <div className="group flex items-center space-x-3">
          {_title}
          <RightArrowSm className="translate-y-[2px] lg:hidden" />
          {/* For some reason, this long arrow below does not work on actual mobile devices */}
          <div className="relative ml-5 hidden h-5 w-[28px] translate-y-[2px] cursor-pointer overflow-hidden transition-all duration-200 ease-in-out group-hover:w-[45px] lg:block">
            <RightArrow className="absolute right-0 " />
          </div>
        </div>
        {_cards && <div className="cards-grid-3 mt-10 gap-y-6 lg:mt-12">{_cards}</div>}
      </div>
    </section>
  ) : (
    <></>
  )
}
