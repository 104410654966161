import classNames from "classnames"
import { motion, useInView } from "framer-motion"
import { useRef } from "react"

import { PostCardSM } from "@components/shared"
import { useSectionPadding } from "@hooks/sectionPadding"
import { useResponsiveMD } from "@hooks/shared"
import { ACFCPTGridBlock } from "@models/blocks"
import { WPPostTypePlural } from "@models/posts"
import { variantsPostGrid } from "@utils/animation"

export const CPTGridBlock = ({
  padding_top,
  padding_bottom,
  section_id,
  sequence,
  title,
  content,
  post_type,
  posts,
}: ACFCPTGridBlock) => {
  const isResponsiveMD = useResponsiveMD()
  const ref = useRef<HTMLDivElement>(null!)
  const isInView = useInView(ref, { once: true })
  const _title = isResponsiveMD ? title.replaceAll(/\r?\n/g, "<br>") : title
  const sectionPadding = useSectionPadding(padding_top, padding_bottom)

  return (
    <section ref={ref} id={section_id} className="scroll-mt-16" style={sectionPadding}>
      <div className="wrapper-sm">
        <div>{sequence}</div>
        <h2
          className="app-h2 mt-6 max-w-[512px] lg:mt-12"
          dangerouslySetInnerHTML={{ __html: _title }}
        />
        <div
          className="wysiwyg content-text mt-8 lg:mt-12 lg:w-1/2"
          dangerouslySetInnerHTML={{ __html: content }}
        />
        <CPTGridCards
          posts={posts}
          className="mt-11 lg:mt-24"
          postType={post_type}
          isInView={isInView}
        />
      </div>
    </section>
  )
}

const CPTGridCards = ({
  posts,
  className,
  postType,
  isInView,
}: {
  posts: ACFCPTGridBlock["posts"]
  className?: string
  postType: WPPostTypePlural
  isInView?: boolean
}) => (
  <div className={classNames("cards-grid-3 gap-y-[22px] text-black", className)}>
    {posts.map((item, i) => (
      <motion.div
        key={item.post}
        variants={variantsPostGrid}
        initial="initial"
        animate="animate"
        custom={isInView}
        transition={{ ease: "easeInOut", duration: 1.25, delay: i * 0.3 }}
      >
        {item.slug && <PostCardSM slug={item.slug} title={item.title ?? ""} postType={postType} />}
      </motion.div>
    ))}
  </div>
)
