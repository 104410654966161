import classNames from "classnames"
import { motion, useAnimation, useInView, Variants } from "framer-motion"
import Image from "next/legacy/image"
import { useEffect, useRef, useState } from "react"

import { useImageMap } from "@contexts/image-map"
import { useLayoutWrapper } from "@contexts/layout-wrapper"
import { useParallaxY } from "@hooks/parallax"
import { useResponsiveLG } from "@hooks/shared"
import { ACFHeroBlock } from "@models/blocks"
import { useScroll } from "@utils/animation"

export const variantsTitle: Variants = {
  init: { y: -50, opacity: 0 },
  enter: { y: 0, opacity: 1, transition: { ease: "easeOut", duration: 1 } },
}

export const variantsImg1: Variants = {
  init: { y: -100, opacity: 0 },
  enter: { y: 0, opacity: 1, transition: { ease: "easeOut", duration: 1 } },
}

export const variantsImg2: Variants = {
  init: { y: 100, opacity: 0 },
  enter: { y: 0, opacity: 1, transition: { ease: "easeOut", duration: 1 } },
}

export const HeroBlock = ({
  section_class,
  title,
  media_1_type,
  image_1,
  video_1,
  media_2_type,
  image_2,
  video_2,
}: ACFHeroBlock) => {
  const imageMap = useImageMap()
  const ref = useRef<HTMLDivElement>(null!)
  const [doneAnimating, setDoneAnimating] = useState(false)
  const [image1Done, setImage1Done] = useState(media_1_type !== "image")
  const [image2Done, setImage2Done] = useState(media_2_type !== "image")
  const isInView = useInView(ref, { once: true, amount: "some" })
  const isResponsiveLG = useResponsiveLG()
  const layoutWrapper = useLayoutWrapper()
  const animation = useAnimation()
  const scrollY = useScroll({ container: layoutWrapper })
  const yText = useParallaxY(scrollY, [0, 500], isResponsiveLG ? [0, 150] : [0, 100])
  const y1 = useParallaxY(scrollY, [0, 500], isResponsiveLG ? [0, 50] : [0, 80])
  const y2 = useParallaxY(scrollY, [0, 500], isResponsiveLG ? [0, -75] : [0, -50])

  // hero images parallax styles
  const stylesText = doneAnimating ? { y: yText } : undefined
  const stylesImg1 = doneAnimating ? { y: y1 } : undefined
  const stylesImg2 = doneAnimating ? { y: y2 } : undefined

  // properly handle hero media types (image/video)
  const img1Media =
    media_1_type === "image" ? (
      // eslint-disable-next-line jsx-a11y/alt-text
      <Image {...imageMap[image_1]} onLoadingComplete={() => setImage1Done(true)} priority />
    ) : (
      <div className="aspect-w-16 aspect-h-9">
        <iframe
          className="border-0"
          src={`https://player.vimeo.com/video/${video_1}?h=20d386e47b&background=1&loop=1`}
          allow="autoplay"
        />
      </div>
    )
  const img2Media =
    media_2_type === "image" ? (
      // eslint-disable-next-line jsx-a11y/alt-text
      <Image {...imageMap[image_2]} onLoadingComplete={() => setImage2Done(true)} priority />
    ) : (
      <div className="aspect-w-16 aspect-h-9">
        <iframe
          className="border-0"
          src={`https://player.vimeo.com/video/${video_2}?h=20d386e47b&background=1&loop=1`}
          allow="autoplay"
        />
      </div>
    )

  // animate the two images when both of them are already loaded
  useEffect(() => {
    if (!doneAnimating && image1Done && image2Done && isInView) {
      animation.start("enter").then(() => setDoneAnimating(true))
    }
  }, [doneAnimating, image1Done, image2Done, isInView, animation])

  return (
    <section
      ref={ref}
      className={classNames("mb-9 overflow-hidden sm:mb-6 md:mb-0", section_class)}
    >
      <div className="wrapper h-[51vh] !px-0 sm:h-[59vh] md:h-[50vh] lg:h-[68vh] lg:min-h-[611px] xl:h-[75vh] xl:min-h-[720px]">
        <div className="absolute-center-x top-[40%] z-10 w-full text-center sm:top-[37%] lg:top-[40%]">
          <motion.div
            className="text-shadow font-serif text-app-h2 lg:text-hero"
            variants={variantsTitle}
            initial="init"
            animate={animation}
            style={stylesText}
            dangerouslySetInnerHTML={{ __html: title }}
          />
        </div>

        <motion.div
          // className="absolute bottom-1/2 w-full lg:bottom-[36%] lg:left-12 lg:w-[48%] 2xl:bottom-[40%]"
          className="absolute bottom-[45%] w-[90%] sm:bottom-[35%] md:bottom-[45%] md:left-12 md:w-[48%]"
          variants={variantsImg1}
          initial="init"
          animate={animation}
          style={stylesImg1}
        >
          {img1Media}
        </motion.div>

        <motion.div
          // className="absolute top-1/2 w-full lg:top-[40%] lg:right-12 lg:w-[48%] 2xl:top-[40%]"
          className="absolute top-[45%] right-0 w-[90%] sm:top-[40%] md:right-12 md:top-[40%] md:ml-0 md:w-[48%]"
          variants={variantsImg2}
          initial="init"
          animate={animation}
          style={stylesImg2}
        >
          {img2Media}
        </motion.div>
      </div>
    </section>
  )
}
