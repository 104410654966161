import { motion, useAnimation, useInView, Variants } from "framer-motion"
import { useEffect, useMemo, useRef } from "react"

import { AppLink } from "@components/shared"
import { useSectionPadding } from "@hooks/sectionPadding"
import { useResponsiveMD } from "@hooks/shared"
import { IconNewsletterArrow } from "@icons/newsletter-arrow"
import { ACFAnimatedMediaBlock } from "@models/blocks"

const variantsLeftWord: Variants = {
  init: { x: 200, opacity: 0 },
  enter: { x: 0, opacity: 1, transition: { duration: 1, ease: "easeOut" } },
}
const variantsRightWord: Variants = {
  init: { x: 200, opacity: 0 },
  enter: { x: 0, opacity: 1, transition: { duration: 1, ease: "easeOut" } },
}

export interface AnimatedMediaProps {
  animate: boolean
}

export const AnimatedMedia = ({
  padding_top,
  padding_bottom,
  sequence,
  title,
  content,
  links,
  left_word,
  right_word,
  animation_type,
}: ACFAnimatedMediaBlock) => {
  const isResponsiveMD = useResponsiveMD()
  const ref = useRef<HTMLDivElement>(null!)
  const isInView = useInView(ref, { once: true, amount: 0.5 })
  const animation = useAnimation()
  const animatedMediaAnimation = useAnimation()
  const sectionPadding = useSectionPadding(padding_top, padding_bottom)

  const _links = links.map(({ label, link_type, link }) => (
    <AppLink
      key={label + link}
      href={link}
      className="animated-media-link group"
      external={link_type === "external"}
    >
      <span className="relative z-10 text-[14px] leading-5">{label}</span>
      <IconNewsletterArrow className="relative z-10 fill-white transition duration-500 group-hover:fill-[#1C3240]" />
    </AppLink>
  ))

  // acf blocks, for some reason, doesn't automatically add <br> or <p> on textareas
  const _title = isResponsiveMD ? title.replaceAll(/\r?\n/g, "<br>") : title

  // animate left and right words when the section is inside the viewport
  useEffect(() => {
    if (isInView) {
      animation.start("enter")
      animatedMediaAnimation.start("enter")
    }
  }, [isInView, animation, animatedMediaAnimation])

  const animatedMediaURL = useMemo(() => {
    switch (animation_type) {
      case "glassesBinoculars":
        return "https://cederquist-animations.vercel.app/animations/glass-binoculars"
      case "earthClock":
        return "https://cederquist-animations.vercel.app/animations/earth-clock"
      case "lampBulb":
        return "https://cederquist-animations.vercel.app/animations/lamp-bulb"
      default:
        return "https://cederquist-animations.vercel.app/animations/earth-clock"
    }
  }, [animation_type])

  return (
    <section ref={ref} style={sectionPadding}>
      <div className="wrapper-full relative overflow-hidden">
        <div className="px-0 lg:px-[138px]">
          <div className="mx-auto max-w-[1068px]">
            <div>{sequence}</div>
            <h2 className="app-h2 mt-6 md:w-1/2" dangerouslySetInnerHTML={{ __html: _title }} />
          </div>
          <div className="relative my-6 sm:my-0">
            <div className="absolute-center-y h-4 w-4 lg:-left-32">
              <div className="absolute-center-x  -rotate-90 ">
                <motion.span
                  className="app-h3 inline-block"
                  variants={variantsLeftWord}
                  initial="init"
                  animate={animation}
                >
                  {left_word}
                </motion.span>
              </div>
            </div>

            <div className="mx-auto max-w-[1068px]">
              <div className="aspect-w-2 aspect-h-1">
                <iframe src={animatedMediaURL} className="border-0 bg-transparent" loading="lazy" />
              </div>
            </div>

            <div className="absolute-center-y right-0 h-4 w-4 lg:-right-32">
              <div className="absolute-center-x rotate-90">
                <motion.span
                  className="app-h3 right-word inline-block"
                  variants={variantsRightWord}
                  initial="init"
                  animate={animation}
                >
                  {right_word}
                </motion.span>
              </div>
            </div>
          </div>

          <div className="mx-auto max-w-[1068px]">
            <div className="flex flex-col items-start space-y-16 lg:flex-row lg:space-y-0 lg:space-x-12">
              <div className="content-text flex-1" dangerouslySetInnerHTML={{ __html: content }} />
              <div className="w-full flex-1 divide-y border-t border-b">{_links}</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
