interface SaveVCardProps {
  firstName: string
  lastName: string
  telephone: string
  mobilePhone: string
  email: string
  title: string
  featuredImageUrl?: string
}

export const saveVCard = async ({
  firstName,
  lastName,
  telephone,
  mobilePhone,
  email,
  title,
  featuredImageUrl,
}: SaveVCardProps) => {
  let params = new URLSearchParams()

  params.append("fname", firstName)
  params.append("lname", lastName)
  telephone && params.append("telephone", telephone)
  mobilePhone && params.append("mobilePhone", mobilePhone)
  email && params.append("email", email)
  title && params.append("title", title)
  featuredImageUrl && params.append("photo", featuredImageUrl)

  window.location.href = `/api/save-vcard/?${params.toString()}`
}
