import { IconProps } from "./props"

export const RightArrowSm = ({ className, style }: IconProps) => {
  return (
    <svg
      className={className}
      style={style}
      width="27"
      height="20"
      viewBox="0 0 27 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.3357 0.11736C17.209 0.244096 17.1365 0.416095 17.1365 0.597147C17.1365 0.778199 17.209 0.950198 17.3357 1.07693L24.5778 8.90742H0.678944C0.497892 8.90742 0.325893 8.97984 0.199157 9.10658C0.0724206 9.23331 0 9.40531 0 9.58636C0 9.76741 0.0724206 9.93941 0.199157 10.0661C0.325893 10.1929 0.497892 10.2653 0.678944 10.2653H24.3696L17.2995 17.8966C17.1727 18.0234 17.1003 18.1954 17.1003 18.3764C17.1003 18.5575 17.1727 18.7295 17.2995 18.8562C17.4262 18.9829 17.5982 19.0554 17.7793 19.0554C17.9603 19.0554 18.1323 18.9829 18.2591 18.8562L26.678 9.80362C26.7413 9.74026 26.7685 9.64973 26.7685 9.5592C26.7685 9.46868 26.7323 9.37815 26.678 9.31478L18.2591 0.262202C18.1504 0.126413 17.9965 0.035887 17.8245 0.00872928C17.6525 -0.0184285 17.4805 0.0177819 17.3357 0.11736Z"
        fill="white"
      />
    </svg>
  )
}
