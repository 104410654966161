import { useRouter } from "next/router"
import useSWRInfinite, { SWRInfiniteKeyLoader } from "swr/infinite"
import { useDebounce } from "use-debounce"

import { AppLocale } from "@models/common"
import { MedarbetareFiltersResponse } from "@models/medarbetare"

const POSTS_PER_PAGE = 6

export const useMedarbetareFilters = (
  query: string,
  position: string,
  specialistomraden: number,
  branscher: number
) => {
  const router = useRouter()
  const clean = query.trim().toLowerCase()
  const [debouncedQuery] = useDebounce(clean, 300)

  const keyProvider: SWRInfiniteKeyLoader = (
    index: number,
    previous: MedarbetareFiltersResponse | null
  ) => {
    if (!previous)
      return [debouncedQuery, position, specialistomraden, branscher, router.locale, index]
    if (previous.hasMore)
      return [debouncedQuery, position, specialistomraden, branscher, router.locale, index]
    return null
  }
  const { data, error, size, setSize } = useSWRInfinite(keyProvider, fetcher)

  return {
    data: data?.flatMap(fragment => fragment.data),
    hasMore: data?.[data.length - 1]?.hasMore,
    isLoadingMore: data?.[size - 1] === undefined,
    loadMore: () => setSize(size + 1),
    error,
  }
}

const fetcher = async ([query, positionSlug, specialistomradenID, branscherID, locale, page]: [
  string,
  string,
  number,
  number,
  AppLocale,
  number
]): Promise<MedarbetareFiltersResponse> => {
  const params = new URLSearchParams({
    q: query,
    position: positionSlug,
    specialistomraden: specialistomradenID.toString(),
    branscher: branscherID.toString(),
    locale,
    page: page.toString(),
    limit: POSTS_PER_PAGE.toString(),
  })

  const response = await fetch("/api/medarbetare/?" + params.toString())
  return await response.json()
}
