declare global {
  interface Window {
    dataLayer: Record<string, any>[]
  }
}

import Image from "next/legacy/image"
import { useRouter } from "next/router"
import { useCallback } from "react"

import { AppLink } from "@components/shared"
import config from "@config/pages/medarbetare.json"
import { useGlobalFields } from "@contexts/acf-global"
import { IconDocument } from "@icons/document"
import { IconVcard } from "@icons/vcard"
import { MedarbetareVCard, WPMedia, WPSearchMedia } from "@models/common"
import { getAppLocale } from "@utils/locale"
import { saveVCard } from "@utils/vcard"

interface MedarbetareGridProps {
  people: {
    slug: string
    name: string
    metadata?: { title: string; subTitle?: string; content: string }[]
    image?: WPMedia | WPSearchMedia
    vcard: MedarbetareVCard
  }[]
  title?: string
  className?: string
}

export const MedarbetareGrid = ({ people, title, className }: MedarbetareGridProps) => {
  // build medarbetare cards
  const _cards = people.map((person, i) => <MedarbetareCard key={i} {...person} />)

  return (
    <div className={className}>
      {title && <h2 className="app-h2 mb-9">{title}</h2>}
      <div className="cards-grid-3 gap-y-[50px]">{_cards}</div>
    </div>
  )
}

export const MedarbetareCard = ({
  slug,
  name,
  metadata,
  image,
  vcard,
}: {
  slug: string
  name: string
  metadata?: { title: string; subTitle?: string; content: string }[]
  image?: WPMedia | WPSearchMedia
  vcard: MedarbetareVCard
}) => {
  const router = useRouter()
  const { etc } = useGlobalFields()
  const appLocale = getAppLocale(router.locale)

  const saveVCardCallback = useCallback(() => {
    saveVCard({ ...vcard, featuredImageUrl: "src" in image! ? image.src : image!.mediaItemUrl })

    // Vcard download tracking
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({ event: "Vcard", name: `${vcard.firstName} ${vcard.lastName}` })
  }, [image, vcard])

  // metadata is null, the person is probably already deleted in the CMS
  if (!metadata) return null

  const _metadata = metadata.slice(0, 1).map(({ title, subTitle, content }) => (
    <div key={title} className="mt-2 text-xs">
      <div>{router.asPath === "/organisation/" ? (subTitle ? subTitle : title) : title}</div>
      <div className="mb-2 mt-3 w-5 border-t border-white"></div>
      <div className="wysiwyg" dangerouslySetInnerHTML={{ __html: content }} />
    </div>
  ))

  return (
    <div className="flex flex-col">
      <AppLink href={`/${config[appLocale]}/${slug}`} className="flex-1" prefetch={false}>
        <div className="relative overflow-hidden rounded-[15px] bg-white/20 backdrop-blur-md">
          {image ? (
            <Image
              src={"src" in image ? image.src : image.mediaItemUrl}
              alt={"alt" in image ? image.alt : image.altText}
              layout="responsive"
              width={590}
              height={370}
              // layout="fill"
              // objectFit="cover"
            />
          ) : (
            <div className="aspect-h-10 aspect-w-16" />
          )}
        </div>

        <h3 className="title-article mt-4">{name}</h3>
        {_metadata}
      </AppLink>
      <div className="mt-4 flex space-x-4">
        <AppLink
          href={`/${config[appLocale]}/${slug}`}
          className="app-btn group flex items-center space-x-2 px-[22px] py-[7px] text-header-link"
          prefetch={false}
        >
          <IconDocument className="fill-white transition duration-300 group-hover:fill-[#1C3240]" />
          <span>{etc.medarbetareInfoLabel}</span>
        </AppLink>

        <button
          className="app-btn group flex items-center space-x-2 px-[22px] py-[7px] text-header-link"
          onClick={saveVCardCallback}
        >
          <IconVcard className="fill-white transition duration-300 group-hover:fill-[#1C3240]" />
          <span>{etc.medarbetareVcardLabel}</span>
        </button>
      </div>
    </div>
  )
}
