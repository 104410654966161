import classNames from "classnames"

import { IconProps } from "./props"

interface IconDropdownArrowProps extends IconProps {
  open: boolean
}

export const IconDropdownArrow = ({ className, open }: IconDropdownArrowProps) => {
  return (
    <div
      className={classNames(
        "inline-block h-3 w-3 origin-center border-r border-b border-white transition-all duration-300",
        open ? "mt-1 -rotate-[135deg]" : "mb-1 rotate-45",
        className
      )}
    />
  )
}
