import { useMemo } from "react"

import { AppSearchInput, SearchSuggestion } from "@components/shared"
import { useSearchSuggestions } from "@hooks/search"
import { useSectionPadding } from "@hooks/sectionPadding"
import { ACFSearchBlock } from "@models/blocks"

export const SearchBlock = ({
  padding_top,
  padding_bottom,
  title,
  placeholder,
}: ACFSearchBlock) => {
  const { items } = useSearchSuggestions()
  const sectionPadding = useSectionPadding(padding_top, padding_bottom)

  // build search suggestions buttons
  const suggestions = useMemo(
    () => items?.map(item => <SearchSuggestion key={item}>{item}</SearchSuggestion>),
    [items]
  )

  return (
    <section style={sectionPadding}>
      <div className="wrapper flex flex-col items-center">
        <h2 className="app-h2 text-center">{title}</h2>
        <AppSearchInput className="mt-5 w-[min(510px,100%)] md:mt-8" placeholder={placeholder} />
        <div className="mt-[18px] flex w-full flex-wrap justify-center sm:space-x-4 md:mt-5">
          {suggestions}
        </div>
      </div>
    </section>
  )
}
