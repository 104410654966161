import { useRouter } from "next/router"
import { useMemo } from "react"
import useSWR from "swr"
import useSWRInfinite, { SWRInfiniteKeyLoader } from "swr/infinite"

import { AppLocale } from "@models/common"
import { WPPostTypeSlug } from "@models/posts"
import { MedarbetareSearchResult, SearchResultsResponse } from "@models/search"

export const useSearch = () => {
  const router = useRouter()
  const query =
    router.isReady && typeof router.query.q === "string" && router.query.q.length > 0
      ? router.query.q
      : null
  const createKey: (type: WPPostTypeSlug, limit: number) => SWRInfiniteKeyLoader =
    (type, limit) => (index: number, previous: SearchResultsResponse | null) => {
      if (!query) return null
      if (!previous) return [type, limit, query, 0, router.locale]
      return index < previous.total / limit ? [type, limit, query, index, router.locale] : null
    }
  const mData = useSWRInfinite(createKey("medarbetare", 3), fetcher)
  const vData = useSWRInfinite(createKey("verksamhetsomraden", 3), fetcher)
  const bData = useSWRInfinite(createKey("branscher", 9), fetcher)
  const pData = useSWRInfinite(createKey("post", 3), fetcher)

  // restructure search results
  const disabled = query === null
  const error = mData.error || vData.error || bData.error || pData.error
  const data = useMemo(() => {
    const medarbetare = mData.data?.flatMap(
      ({ data }) => data as unknown as MedarbetareSearchResult[]
    )
    const verksamhetsomraden = vData.data?.flatMap(({ data }) => data)
    const branscher = bData.data?.flatMap(({ data }) => data)
    const posts = pData.data?.flatMap(({ data }) => data)

    // return null to show loading state
    if (!(medarbetare && verksamhetsomraden && branscher && posts)) return null

    return {
      isEmpty:
        medarbetare.length === 0 &&
        verksamhetsomraden.length === 0 &&
        branscher.length === 0 &&
        posts.length === 0,
      medarbetare: {
        total: mData.data?.[0].total ?? 0,
        data: medarbetare,
        loadMore: () => mData.setSize(mData.size + 1),
        isLoadingMore: mData.isValidating,
        hasMore: medarbetare.length < (mData.data?.[0].total ?? 0),
      },
      verksamhetsomraden: {
        total: vData.data?.[0].total ?? 0,
        data: verksamhetsomraden,
        loadMore: () => vData.setSize(vData.size + 1),
        isLoadingMore: vData.isValidating,
        hasMore: verksamhetsomraden.length < (vData.data?.[0].total ?? 0),
      },
      branscher: {
        total: bData.data?.[0].total ?? 0,
        data: branscher,
        loadMore: () => bData.setSize(bData.size + 1),
        isLoadingMore: bData.isValidating,
        hasMore: branscher.length < (bData.data?.[0].total ?? 0),
      },
      posts: {
        total: pData.data?.[0].total ?? 0,
        data: posts,
        loadMore: () => pData.setSize(pData.size + 1),
        isLoadingMore: pData.isValidating,
        hasMore: posts.length < (pData.data?.[0].total ?? 0),
      },
    }
  }, [mData, vData, bData, pData])

  return { data, error, disabled }
}

const fetcher = async ([type, limit, q, index, locale]: [
  WPPostTypeSlug,
  number,
  string,
  number,
  AppLocale
]): Promise<SearchResultsResponse> => {
  const params = new URLSearchParams({
    q,
    page: index.toString(),
    type: type.toString(),
    limit: limit.toString(),
    locale,
  })
  const response = await fetch(`/api/search/?` + params.toString())

  return await response.json()
}

export const useSearchSuggestions = () => {
  const { locale } = useRouter()
  const result = useSWR(locale, fetchSearchSuggestions)

  return { items: result.data, ...result }
}

const fetchSearchSuggestions = async (locale: string): Promise<string[]> => {
  const params = new URLSearchParams({ locale })
  const response = await fetch(`/api/search-suggestions/?` + params.toString())

  return await response.json()
}
