import { MotionValue, useSpring, useTransform } from "framer-motion"

export function useParallaxY(
  scrollY: MotionValue<number>,
  inputRange: number[],
  outputRange: number[]
) {
  const yRange = useTransform(scrollY, inputRange, outputRange)
  // apply a spring to ease the result
  return useSpring(yRange, { stiffness: 600, damping: 100, bounce: 0 })
}
