import { IconProps } from "./props"

export const IconVcard = ({ className, style }: IconProps) => {
  return (
    <svg className={className} width="14" height="16" viewBox="0 0 14 16" style={style}>
      <g clipPath="url(#clip0_533_3840)">
        <path d="M8.986 9.68c.58-.25 1.12-.6 1.58-1.07a5.052 5.052 0 000-7.14 5.04 5.04 0 00-7.13.01 5.052 5.052 0 001.58 8.21 6.552 6.552 0 00-4.59 6.25h.75c0-3.21 2.61-5.82 5.82-5.82 3.21 0 5.82 2.61 5.82 5.82h.75c0-2.93-1.93-5.42-4.58-6.26zm-5.02-7.67c.84-.84 1.94-1.26 3.04-1.26s2.2.42 3.04 1.26a4.316 4.316 0 010 6.08c-.81.81-1.89 1.26-3.04 1.26-1.15 0-2.23-.45-3.04-1.26a4.303 4.303 0 010-6.07v-.01z"></path>
      </g>
      <defs>
        <clipPath id="clip0_533_3840">
          <path fill="#fff" d="M0 0H13.15V15.94H0z" transform="translate(.426)"></path>
        </clipPath>
      </defs>
    </svg>
  )
}
