import { useEffect, useMemo, useState } from "react"

import { SearchInput } from "@components/shared"
import { AppDropdown } from "@components/widgets/dropdown"
import { MedarbetareGrid } from "@components/widgets/medarbetare-grid"
import { useGlobalFields } from "@contexts/acf-global"
import { useMedarbetareContext } from "@contexts/medarbetare"
import { useMedarbetareFilters } from "@hooks/medarbetare"
import { useSectionPadding } from "@hooks/sectionPadding"
import { IconLoading } from "@icons/loading"
import { ACFMedarbetareCardsBlock } from "@models/blocks"
import { useInView } from "react-intersection-observer"

export const MedarbetareCardsBlock = ({
  padding_top,
  padding_bottom,
  search_placeholder,
  all_medarbetare_label,
  all_specialistomraden_label,
  all_branscher_label,
}: ACFMedarbetareCardsBlock) => {
  const { etc } = useGlobalFields()
  const { positions, verksamhetsomraden, branscher } = useMedarbetareContext()
  const [query, setQuery] = useState("")
  const [position, setPosition] = useState("all")
  const [specialistomradenID, setSpecialistomradenID] = useState(-1)
  const [branscherID, setBranscherID] = useState(-1)
  const { data, error, hasMore, isLoadingMore, loadMore } = useMedarbetareFilters(
    query,
    position,
    specialistomradenID,
    branscherID
  )
  const sectionPadding = useSectionPadding(padding_top, padding_bottom)
  const { ref: refLoading, inView: loadingInView } = useInView()

  // build dropdown options
  const positionOptions = useMemo(
    () => [
      { key: "all", label: all_medarbetare_label },
      ...positions
        .filter(({ slug }) => slug !== "organisation" && slug !== "organisation-en")
        .map(({ slug, name }) => ({ key: slug, label: name })),
    ],
    [all_medarbetare_label, positions]
  )
  const verksamhetsomradenOptions = useMemo(
    () => [
      { key: -1, label: all_specialistomraden_label },
      ...verksamhetsomraden.map(({ databaseId, title }) => ({ key: databaseId, label: title })),
    ],
    [all_specialistomraden_label, verksamhetsomraden]
  )
  const branscherOptions = useMemo(
    () => [
      { key: -1, label: all_branscher_label },
      ...branscher.map(({ databaseId, title }) => ({ key: databaseId, label: title })),
    ],
    [all_branscher_label, branscher]
  )

  // load more when in view
  useEffect(() => {
    if (loadingInView && !isLoadingMore && hasMore) loadMore()
  }, [loadingInView, isLoadingMore, hasMore, loadMore])

  return (
    <section style={sectionPadding}>
      <div className="wrapper-sm">
        <SearchInput
          className="mx-auto w-[min(510px,100%)]"
          placeholder={search_placeholder}
          value={query}
          onChange={query => {
            setPosition("all")
            setSpecialistomradenID(-1)
            setBranscherID(-1)
            setQuery(query)
          }}
        />

        {/* Filters row */}
        <div className="mt-12 grid gap-0 md:grid-cols-3 md:gap-11">
          <AppDropdown
            value={position}
            options={positionOptions}
            onChange={value => {
              setQuery("")
              setPosition(value)
            }}
            buttonClasses="border-b-0 md:border-b"
          />
          <AppDropdown
            value={specialistomradenID}
            options={verksamhetsomradenOptions}
            onChange={value => {
              setQuery("")
              setSpecialistomradenID(value)
            }}
            buttonClasses="border-b-0 md:border-b"
          />
          <AppDropdown
            value={branscherID}
            options={branscherOptions}
            onChange={value => {
              setQuery("")
              setBranscherID(value)
            }}
            buttonClasses="border-b"
          />
        </div>

        {error && <div className="mt-12">an error has occurred</div>}
        {data ? (
          <>
            <MedarbetareGrid className="mt-12" people={data} />
            {hasMore && (
              <div ref={refLoading} className="relative mt-14">
                <IconLoading className="mx-auto w-10 fill-white" />
              </div>
            )}
          </>
        ) : (
          <div className="relative mt-12">
            <IconLoading className="absolute-center-x w-10 fill-white" />
          </div>
        )}
      </div>
    </section>
  )
}
