import { IconProps } from "./props"

export const RightArrow = ({ className, style }: IconProps) => {
  return (
    <svg
      className={className}
      style={style}
      width="46"
      height="20"
      viewBox="0 0 46 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35.5425 0.117697C35.4154 0.244796 35.3428 0.417289 35.3428 0.598859C35.3428 0.78043 35.4154 0.952922 35.5425 1.08002L42.8053 8.93296H0.68089C0.49932 8.93296 0.326827 9.00559 0.199728 9.13269C0.0726283 9.25979 0 9.43228 0 9.61385H0C0 9.79542 0.0726283 9.96791 0.199728 10.095C0.326827 10.2221 0.49932 10.2947 0.68089 10.2947H42.5965L35.5062 17.9479C35.3791 18.075 35.3064 18.2475 35.3064 18.4291C35.3064 18.6107 35.3791 18.7832 35.5062 18.9103C35.6333 19.0374 35.8058 19.11 35.9873 19.11C36.1689 19.11 36.3414 19.0374 36.4685 18.9103L44.9115 9.83173C44.9751 9.76818 45.0023 9.6774 45.0023 9.58661C45.0023 9.49583 44.966 9.40504 44.9115 9.34149L36.4685 0.262953C36.3595 0.126775 36.2052 0.0359899 36.0327 0.00875431C35.8602 -0.0184813 35.6877 0.0178328 35.5425 0.117697Z"
        fill="white"
      />
    </svg>
  )
}
