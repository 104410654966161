import { Listbox } from "@headlessui/react"
import classNames from "classnames"
import { Key, useMemo } from "react"

import { IconDropdownArrow } from "@icons/dropdown-arrow"

interface AppDropdownProps<T extends Key> {
  value: T
  options: { key: T; label: string }[]
  onChange: (newValue: T) => void
  buttonClasses?: string
}

export const AppDropdown = <T extends Key>({
  value,
  options,
  onChange,
  buttonClasses,
}: AppDropdownProps<T>) => {
  const _options = useMemo(
    () =>
      options.map(({ key, label }) => (
        <Listbox.Option className="cursor-pointer select-none px-3 text-sm" key={key} value={key}>
          <span className="text-hover-fill block before:text-gray-400" data-content={label}>
            {label}
          </span>
        </Listbox.Option>
      )),
    [options]
  )
  const _selectedLabel = useMemo(
    () => options.filter(({ key }) => key === value)?.[0].label ?? "<no-label>",
    [options, value]
  )

  return (
    <Listbox as="div" className="relative" value={value} onChange={onChange}>
      {({ open }) => (
        <>
          <Listbox.Button
            className={classNames(
              "flex w-full origin-center items-center justify-between border-t border-white p-3 text-left text-sm",
              open ? "border-b-transparent text-transparent" : "",
              buttonClasses
            )}
          >
            <span>{_selectedLabel}</span>
            <IconDropdownArrow
              className={classNames("relative", open ? "z-20" : "z-0")}
              open={open}
            />
          </Listbox.Button>
          <Listbox.Options
            className={classNames(
              "absolute left-0 top-px z-10 max-h-[490px] w-full space-y-2 overflow-y-auto border-b bg-black/10 pt-[12px] pb-3 backdrop-blur-md scrollbar-thin scrollbar-track-transparent scrollbar-thumb-white/60",
              open ? null : "hidden"
            )}
            static
          >
            {_options}
          </Listbox.Options>
        </>
      )}
    </Listbox>
  )
}
