import { useResponsiveLG } from "./shared"

export function useSectionPadding(paddingTop: string, paddingBottom: string) {
  const basePadding = useResponsiveLG() ? 4 : 1.5

  return {
    paddingTop: parseInt(paddingTop) * basePadding,
    paddingBottom: parseInt(paddingBottom) * basePadding,
  }
}
