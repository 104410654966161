import classNames from "classnames"
import { motion, useAnimation, useInView, Variants } from "framer-motion"
import Image from "next/legacy/image"
import { useEffect, useRef, useState } from "react"

import { useImageMap } from "@contexts/image-map"
import { useLayoutWrapper } from "@contexts/layout-wrapper"
import { useParallaxY } from "@hooks/parallax"
import { useSectionPadding } from "@hooks/sectionPadding"
import { useResponsiveMD } from "@hooks/shared"
import { ACFTitleMultiImagesBlock } from "@models/blocks"
import { useScroll } from "@utils/animation"

const variantsTitle: Variants = {
  init: { y: -50, opacity: 0 },
  enter: { y: 0, opacity: 1, transition: { ease: "easeOut", duration: 1, delay: 0.2 } },
}

const variantsImg1: Variants = {
  init: { y: -100, opacity: 0 },
  enter: { y: 0, opacity: 1, transition: { ease: "easeOut", duration: 1, delay: 0.2 } },
}

const variantsImg2: Variants = {
  init: { y: 100, opacity: 0 },
  enter: { y: 0, opacity: 1, transition: { ease: "easeOut", duration: 1, delay: 0.2 } },
}

const variantsImg3: Variants = {
  init: { x: -100, opacity: 0 },
  enter: { x: 0, opacity: 1, transition: { ease: "easeOut", duration: 1, delay: 0.2 } },
}

export const TitleMultiImagesBlock = ({
  padding_top,
  padding_bottom,
  section_class,
  title,
  image_1,
  image_2,
  image_3,
}: ACFTitleMultiImagesBlock) => {
  const ref = useRef<HTMLDivElement>(null!)
  const [doneAnimating, setDoneAnimating] = useState(false)
  const [startParallax, setStartParallax] = useState(false)
  const imageMap = useImageMap()
  const animation = useAnimation()
  const isInView = useInView(ref, { once: true, amount: "some" })
  const sectionPadding = useSectionPadding(padding_top, padding_bottom)
  const offset = 100

  const [elementTop, setElementTop] = useState(0)
  const [clientHeight, setClientHeight] = useState(0)

  const layoutWrapper = useLayoutWrapper()
  const scrollY = useScroll({ container: layoutWrapper })
  const isResponsiveMD = useResponsiveMD()

  // start animating our element when we've scrolled it into view
  const initial = elementTop - clientHeight / 2
  // end our animation when we've scrolled the offset specified
  const final = elementTop + clientHeight

  const yText = useParallaxY(scrollY, [initial, final], isResponsiveMD ? [0, 0] : [-10, 0])
  const y1 = useParallaxY(scrollY, [initial, final], isResponsiveMD ? [-50, 100] : [0, 100])
  const y2 = useParallaxY(scrollY, [initial, final], [-20, 20])
  const y3 = useParallaxY(scrollY, [initial, final], isResponsiveMD ? [30, -60] : [0, -80])

  const [image1Done, setImage1Done] = useState(false)
  const [image2Done, setImage2Done] = useState(false)
  const [image3Done, setImage3Done] = useState(false)

  const stylesText = { y: yText }
  const stylesImg1 = { y: y1 }
  const stylesImg2 = { y: y2 }
  const stylesImg3 = { y: y3 }

  useEffect(() => {
    // if (!doneAnimating && image1Done && image2Done && image3Done && isInView) {
    if (!doneAnimating && image1Done && image2Done && isInView) {
      // animation.start("enter")
      // setDoneAnimating(true)
      animation.start("enter").then(() => setDoneAnimating(true))
    }
  }, [image1Done, image2Done, image3Done, isInView, animation, doneAnimating])

  useEffect(() => {
    const element = ref.current
    // save our layout measurements in a function in order to trigger
    // it both on mount and on resize
    const onResize = () => {
      // use getBoundingClientRect instead of offsetTop in order to
      // get the offset relative to the viewport
      setElementTop(element!.getBoundingClientRect().top + window.scrollY || window.pageYOffset)
      setClientHeight(window.innerHeight)
    }
    onResize()
    window.addEventListener("resize", onResize)
    return () => window.removeEventListener("resize", onResize)
  }, [ref])

  useEffect(() => {
    const wrapper = layoutWrapper.current
    const onScroll = () => {
      if (!startParallax && doneAnimating) {
        setStartParallax(true)
        layoutWrapper.current!.removeEventListener("scroll", onScroll)
      }
    }

    if (wrapper) wrapper.addEventListener("scroll", onScroll)
    return () => {
      if (wrapper) wrapper.removeEventListener("scroll", onScroll)
    }
  }, [doneAnimating, layoutWrapper, startParallax])

  return (
    <section
      ref={ref}
      id={section_class}
      className={classNames("mb-12 overflow-hidden h900:mb-0", section_class)}
      style={sectionPadding}
    >
      <div className="wrapper relative h-[50vh] sm:h-[60vh] lg:h-screen hxl:h-[70vh]">
        <div className="absolute-center-x top-[50%] z-10 w-full -translate-y-1/2 text-center md:top-[32%] lg:top-[35%]">
          <motion.div
            className="text-shadow font-serif text-app-h2 lg:text-hero"
            variants={variantsTitle}
            initial="init"
            animate={animation}
            style={startParallax ? stylesText : undefined}
            dangerouslySetInnerHTML={{ __html: title }}
          />
        </div>

        {imageMap[image_1] && (
          <motion.div
            className="absolute left-0 bottom-[40%] w-[91%] sm:bottom-[35%] md:bottom-[50%] md:left-12 md:w-[61%] lg:bottom-[45%]"
            variants={variantsImg1}
            initial="init"
            animate={animation}
            style={startParallax ? stylesImg1 : undefined}
          >
            {/* eslint-disable-next-line jsx-a11y/alt-text */}
            <Image {...imageMap[image_1]} onLoadingComplete={() => setImage1Done(true)} />
          </motion.div>
        )}

        {imageMap[image_2] && (
          <motion.div
            className="absolute right-0 top-[50%] z-[2] w-[91%] sm:top-[35%] md:top-[26%] md:right-12 md:w-[45%]"
            variants={variantsImg2}
            initial="init"
            animate={animation}
            style={startParallax ? stylesImg3 : undefined}
          >
            {/* eslint-disable-next-line jsx-a11y/alt-text */}
            <Image {...imageMap[image_2]} onLoadingComplete={() => setImage2Done(true)} />
          </motion.div>
        )}

        {imageMap[image_3] && (
          <motion.div
            className="absolute left-[28%] top-[35%] z-[1] hidden w-[30%] md:block"
            variants={variantsImg3}
            initial="init"
            animate={animation}
            style={startParallax ? stylesImg2 : undefined}
          >
            {/* eslint-disable-next-line jsx-a11y/alt-text */}
            <Image {...imageMap[image_3]} onLoadingComplete={() => setImage3Done(true)} />
          </motion.div>
        )}
      </div>
    </section>
  )
}
