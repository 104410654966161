import { memo } from "react"

import { ACFGenericBlock } from "@models/blocks"
import {
    isAnimatedMediaBlock, isArticlesBlock, isCPTGridBlock, isHeroBlock, isMedarbetareCardsBlock,
    isSearchBlock, isTitleMultiImagesBlock, isTwoColumnTextBlock, isTwoColumnTextExtraBlock
} from "@utils/type-guards"

import { AnimatedMedia } from "./animated-media"
import { ArticlesBlock } from "./articles"
import { CPTGridBlock } from "./cpt-grid"
import { HeroBlock } from "./hero"
import { MedarbetareCardsBlock } from "./medarbetare-cards"
import { SearchBlock } from "./search"
import { TitleMultiImagesBlock } from "./title-multi-images"
import { TwoColumnTextBlock } from "./two-column-text"
import { TwoColumnTextExtraBlock } from "./two-column-text-extra"

interface AcfBlocksContainerProps {
  blocks: ACFGenericBlock[]
}

const _AcfBlocksContainer = ({ blocks }: AcfBlocksContainerProps) => (
  <>{blocks.map(differentiator)}</>
)

export const AcfBlocksContainer = memo(_AcfBlocksContainer)

const differentiator = (block: ACFGenericBlock) => {
  if (isHeroBlock(block)) return <HeroBlock key={block.id} {...block} />
  if (isSearchBlock(block)) return <SearchBlock key={block.id} {...block} />
  if (isAnimatedMediaBlock(block)) return <AnimatedMedia key={block.id} {...block} />
  if (isArticlesBlock(block)) return <ArticlesBlock key={block.id} {...block} />
  if (isTwoColumnTextBlock(block)) return <TwoColumnTextBlock key={block.id} {...block} />
  if (isTwoColumnTextExtraBlock(block)) return <TwoColumnTextExtraBlock key={block.id} {...block} />
  if (isTitleMultiImagesBlock(block)) return <TitleMultiImagesBlock key={block.id} {...block} />
  if (isMedarbetareCardsBlock(block)) return <MedarbetareCardsBlock key={block.id} {...block} />
  if (isCPTGridBlock(block)) return <CPTGridBlock key={block.id} {...block} />

  throw new Error(`Encountered unknown block while differentiating between blocks`)
}
