import {
    ACFAnimatedMediaBlock, ACFArticlesBlock, ACFCPTGridBlock, ACFHeroBlock,
    ACFMedarbetareCardsBlock, ACFSearchBlock, ACFTitleMultiImagesBlock, ACFTwoColumnTextBlock,
    ACFTwoColumnTextExtraBlock
} from "@models/blocks"

/**
 * ACF blocks type guards
 */

export const isHeroBlock = (object: any): object is ACFHeroBlock =>
  typeof object === "object" && object !== null && object.name === "acf/hero"

export const isSearchBlock = (object: any): object is ACFSearchBlock =>
  typeof object === "object" && object !== null && object.name === "acf/search"

export const isAnimatedMediaBlock = (object: any): object is ACFAnimatedMediaBlock =>
  typeof object === "object" && object !== null && object.name === "acf/animated-media"

export const isArticlesBlock = (object: any): object is ACFArticlesBlock =>
  typeof object === "object" && object !== null && object.name === "acf/articles"

export const isTwoColumnTextBlock = (object: any): object is ACFTwoColumnTextBlock =>
  typeof object === "object" && object !== null && object.name === "acf/two-column-text"

export const isTwoColumnTextExtraBlock = (object: any): object is ACFTwoColumnTextExtraBlock =>
  typeof object === "object" && object !== null && object.name === "acf/two-column-text-extra"

export const isTitleMultiImagesBlock = (object: any): object is ACFTitleMultiImagesBlock =>
  typeof object === "object" && object !== null && object.name === "acf/title-multiple-images"

export const isMedarbetareCardsBlock = (object: any): object is ACFMedarbetareCardsBlock =>
  typeof object === "object" && object !== null && object.name === "acf/medarbetare-cards"

export const isCPTGridBlock = (object: any): object is ACFCPTGridBlock =>
  typeof object === "object" && object !== null && object.name === "acf/cpt-grid"
