import classNames from "classnames"

import { FadeInSlideUp } from "@components/shared"
import { useSectionPadding } from "@hooks/sectionPadding"
import { useResponsiveLG } from "@hooks/shared"
import { ACFTwoColumnTextBlock } from "@models/blocks"

export const TwoColumnTextBlock = ({
  padding_top,
  padding_bottom,
  section_class,
  heading,
  sequence,
  title,
  left_content,
  right_content,
  right_content_group_title,
  right_content_group_content,
}: ACFTwoColumnTextBlock) => {
  const isResponsiveLG = useResponsiveLG()
  // Commented to "follow the design"
  // const sectionPadding = useSectionPadding(padding_top, padding_bottom)

  return (
    <section className={classNames("pt-10 sm:pt-0", section_class)}>
      <FadeInSlideUp className="wrapper-sm" threshold={!isResponsiveLG ? 0.25 : 0.5}>
        <div className="om-oss-sequence mb-14 flex-1 lg:hidden">{sequence}</div>
        <div className="flex lg:space-x-12">
          <h3 className="flex-1">{heading}</h3>
          <div className="om-oss-sequence hidden flex-1 lg:block">{sequence}</div>
        </div>
        <div className="mt-5 flex flex-col lg:flex-row lg:space-x-12">
          <h2 className="app-h2 flex-1">{title}</h2>
          <div className="flex-1"></div>
        </div>
        <div className="mt-8 flex flex-col space-y-14 lg:mt-7 lg:flex-row lg:space-x-12 lg:space-y-0">
          <div
            className="wysiwyg content-text flex-1"
            dangerouslySetInnerHTML={{ __html: left_content }}
          />

          {right_content_group_title && (
            <div className="wysiwyg content-text flex-1">
              <h2 className="app-h2 flex-1">{right_content_group_title}</h2>
              <div
                className="wysiwyg mt-8"
                dangerouslySetInnerHTML={{ __html: right_content_group_content }}
              />
            </div>
          )}
        </div>
      </FadeInSlideUp>
    </section>
  )
}
